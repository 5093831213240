import React from "react";
import incode8_logo from '@assets/img/logo/incode8_logo.png';
import {Link} from 'react-router-dom'
import {
  MdBusiness, MdDashboard, MdInsertChartOutlined, MdStar, MdOutlineCampaign, MdSend, MdAlignHorizontalLeft,
  MdSupportAgent, MdAccountCircle, MdMobileFriendly, MdNotificationsNone, MdQuestionAnswer,
  MdOutlineFeed, MdSettingsEthernet, MdOutlineFavoriteBorder, MdPostAdd, MdEmail, MdCategory, MdOutlineFilterAlt,
  MdOutlineMonetizationOn, MdOutlineLocationOn, MdOutlineExtension, MdOutlineHotelClass, MdOutlineArticle, MdList,
  MdArrowCircleDown, MdOutlineFormatListNumbered, MdOutlineCategory, MdOutlinePayments, MdOutlineGrade,
  MdOutlineCreateNewFolder, MdManageAccounts, MdOutlinePictureAsPdf, MdFaceRetouchingNatural
} from 'react-icons/md'
import {BsJournalText, BsBoxSeam, BsTicket} from 'react-icons/bs'
import {BiSend, BiUserPlus} from 'react-icons/bi'
import {VscSymbolKeyword} from 'react-icons/vsc'
import {AiOutlineBook, AiOutlineForm, AiOutlineShop, AiOutlineKey} from 'react-icons/ai'
import {FiMail, FiShoppingBag, FiMonitor} from 'react-icons/fi';
import {CgDollar} from 'react-icons/cg';
import {GiCargoCrate} from 'react-icons/gi';
import bemNames from "@utils/bemnames";
import {Nav, Navbar} from 'reactstrap'
import {FaRegListAlt} from "react-icons/fa";
import {GrGroup} from "react-icons/gr";
import {usertype} from "@static/Config";
import NavSidebar from "@components/Layout/NavSidebar";
import {TbPhotoCircle} from "react-icons/tb";

const bem = bemNames.create('sidebar');

const {admin: rnd, admin_management_support: management, admin_marketing: marketing} = usertype;

const navMarketData = [
    {to: '/manage-market-data', name: '마켓데이터', exact: true, Icon: MdOutlineArticle, permissions: [rnd, management]}
]
const navDashboard = [
    {to: '/dashboard', name: '대시보드', exact: true, Icon: MdDashboard, permissions: [rnd, management, marketing]}
];
const navSupport = [
  { to: '/manage-service/demo-apply', name: '데모 신청 관리', exact: true, Icon: FaRegListAlt, permissions: [rnd, management] },
  { to: '/manage-service/contents-studio', name: '생성 콘텐츠 관리', exact: true, Icon: MdOutlineCreateNewFolder, permissions: [rnd, management] },
  { to: '/manage-support/notice', name: '공지사항', exact: true, Icon: MdNotificationsNone, permissions: [rnd, management] },
  { to: '/manage-support/faq', name: 'FAQ', exact: true, Icon: MdSupportAgent, permissions: [rnd, management] },
  { to: '/manage-support/question', name: '1:1 문의', exact: true, Icon: MdQuestionAnswer, permissions: [rnd, management] },
  { to: '/manage-support/terms', name: '이용약관/서비스정책', exact: true, Icon: MdOutlineFeed, permissions: [rnd, management] },
];
const navUser = [
  { to: '/manage-user/auth-e-commerce', name: 'E 커머스 인증 기록', exact: true, Icon: AiOutlineShop, permissions: [rnd, management] },
  { to: '/manage-user/auth-sns-platform', name: 'SNS 인증 기록', exact: true, Icon: MdMobileFriendly, permissions: [rnd, management] },
  { to: '/manage-user/user', name: '회원 리스트 관리', exact: true, Icon: MdManageAccounts, permissions: [rnd, management] },
];
const navProduct = [
  {to: '/manage-product/monitoring', name: '모니터링 관리', exact: true, Icon: FiMonitor, permissions: [rnd, management] },
  {to: '/manage-product/product', name: '상품 관리', exact: true, Icon: BsBoxSeam, permissions: [rnd, management] },
  {to: '/manage-product/keyword', name: '키워드 관리', exact: true, Icon: VscSymbolKeyword, permissions: [rnd, management] }
]
const navCampaign = [
  { to: '/manage-campaign', name: '캠페인', exact: true, Icon: MdOutlineCampaign, permissions: [rnd, management, marketing] },
];
const navStats = [
  { to: '/stats/influencer', name: '왕홍 통계', exact: true, Icon: MdStar, permissions: [rnd, management] },
  { to: '/stats/brand', name: '브랜드 통계', exact: true, Icon: MdBusiness, permissions: [rnd, management] },
  { to: '/stats/campaign', name: '캠페인 통계', exact: true, Icon: MdOutlineCampaign, permissions: [rnd, management] },
];
const navContents = [
  { to: '/manage-contents/category-mapping', name: '게시글 카테고리 메핑', exact: true, Icon: MdSettingsEthernet, permissions: [rnd] },
  { to: '/manage-contents/post-like-collect', name: '게시글 좋아요/찜하기', exact: true, Icon: MdOutlineFavoriteBorder, permissions: [rnd] },
  { to: '/manage-contents/group-buying', name: '공동구매 리스트', exact: true, Icon: GrGroup, permissions: [rnd, management, marketing] },
  { to: '/manage-contents/influencer', name: '인플루언서 관리', exact: true, Icon: MdFaceRetouchingNatural, permissions: [rnd, management, marketing] },
  { to: '/manage-contents/sns-contents', name: 'SNS 콘텐츠 관리', exact: true, Icon: TbPhotoCircle, permissions: [rnd, management, marketing] },
];
const navFilter = [
  { to: '/manage-filter/sns-platform', name: 'SNS 플랫폼 관리', exact: true, Icon: MdEmail, permissions: [rnd] },
  { to: '/manage-filter/shopping-mall-platform', name: '쇼핑몰 플랫폼', exact: true, Icon: AiOutlineShop, permissions: [rnd]},
  { to: '/manage-filter/category', name: 'SNS 카테고리 관리', exact: true, Icon: MdCategory, permissions: [rnd] },
  { to: '/manage-filter/shopping-mall-category', name: '쇼핑몰 카테고리 관리', exact: true, Icon: MdOutlineCategory, permissions: [rnd] },
  { to: '/manage-filter/user-level', name: '회원 등급 관리', exact: true, Icon: MdOutlineHotelClass, permissions: [rnd] },
  { to: '/manage-filter/user-type', name: '회원 타입 관리', exact: true, Icon: MdOutlineExtension, permissions: [rnd] },
  { to: '/manage-filter/influencer-grade', name: '인플루언서 등급 관리', exact: true, Icon: MdOutlineGrade, permissions: [rnd]},
  { to: '/manage-filter/contents-studio-type', name: '생성 컨텐츠 타입 관리', exact: true, Icon: MdList, permissions: [rnd]},
  { to: '/manage-filter/keyword', name: '키워드 관리', exact: true, Icon: VscSymbolKeyword, permissions: [rnd]},
  { to: '/manage-filter/crawling-keyword-type', name: '수집 키워드 타입', exact: true, Icon: AiOutlineKey, permissions: [rnd] },
  { to: '/manage-filter/range-follower', name: '팔로워 범위 관리', exact: true, Icon: MdAlignHorizontalLeft, permissions: [rnd] },
  { to: '/manage-filter/currency', name: '통화 관리', exact: true, Icon: MdOutlineMonetizationOn, permissions: [rnd] },
  { to: '/manage-filter/country', name: '국가 관리', exact: true, Icon: MdOutlineLocationOn, permissions: [rnd] },
  { to: '/manage-filter/industry', name: '업태 관리', exact: true, Icon: BiUserPlus, permissions: [rnd]},
  { to: '/manage-filter/hscode', name: 'hscode 관리', exact: true, Icon: GiCargoCrate, permissions: [rnd] },
  { to: '/manage-filter/ticket', name: '상품 티켓 관리', exact: true, Icon:  BsTicket, permissions: [rnd]},
  // { to: '/manage-filter/bank', name: '은행 관리', exact: true, Icon: BsBank2 },
];
const navTranslation = [
  { to: '/manage-translation/keyword', name: '번역 키워드 관리', exact: true, Icon: BsJournalText, permissions: [rnd, management] },
];
const navCrawling = [
  { to: '/manage-crawling/alc-crawling-status', name: '우선수집 수집현황', exact: true, Icon: MdPostAdd, permissions: [rnd] },
];
const navPayment = [
  { to: '/manage-payment/payment-history', name: '결제내역 조회', exact: true, Icon: MdOutlinePayments, permissions: [rnd, management] },
];
const navMail = [
  { to: '/manage-mail/send-mail', name: '자동/수동 발신', exact: true, Icon: BiSend, permissions: [rnd, management] },
  { to: '/manage-mail/send-mail-list', name: '발신 기록', exact: true, Icon: MdOutlineFormatListNumbered, permissions: [rnd, management] },
  { to: '/manage-mail/mail-form', name: '양식 관리', exact: true, Icon: AiOutlineForm, permissions: [rnd, management] },
];
const navInvoice = [
  { to: '/invoice', name: '인보이스', exact: true, Icon: MdOutlinePictureAsPdf, permissions: [rnd, management, marketing] },
];

class Sidebar extends React.Component {
  state = {
    isOpenSupport: false,
    isOpenUser: false,
    isOpenCampaign: false,
    isOpenStats: false,
    isOpenContents: false,
    isOpenFilter: false,
    isOpenTranslation: false,
    isOpenCrawling: false,
    isOpenPayment: false,
    isOpenMail: false,
    isOpenProduct: false,
  };

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];
      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  render() {
    return (
      <aside className={bem.b()}>
        <div className={bem.e('content')}>
          <Navbar>
            <Link to='/dashboard'>
              <img
                src={incode8_logo}
                width='140'
                className="pr-2"
                alt='incode8 Logo'
              />
            </Link>
          </Navbar>
          <Nav vertical>
            <NavSidebar nav_list={navDashboard} bem={bem}/>
            <NavSidebar
              nav_list={navSupport}
              bem={bem}
              sub_sidebar={{
                Icon: MdSupportAgent,
                handleClick: this.handleClick,
                name_ko: '서비스 관리',
                name_en: 'Support',
                is_open: this.state.isOpenSupport
              }}
            />
            <NavSidebar
              nav_list={navUser}
              bem={bem}
              sub_sidebar={{
                Icon: MdAccountCircle,
                handleClick: this.handleClick,
                name_ko: '회원 관리',
                name_en: 'User',
                is_open: this.state.isOpenUser
              }}
            />
            <NavSidebar
              nav_list={navProduct}
              bem={bem}
              sub_sidebar={{
                Icon: FiShoppingBag,
                handleClick: this.handleClick,
                name_ko: 'EC커머스',
                name_en: 'Product',
                is_open: this.state.isOpenProduct
              }}
            />
            <NavSidebar nav_list={navCampaign} bem={bem}/>
            <NavSidebar nav_list={navMarketData} bem={bem}/>
            <NavSidebar
              nav_list={navStats}
              bem={bem}
              sub_sidebar={{
                Icon: MdInsertChartOutlined,
                handleClick: this.handleClick,
                name_ko: '통계 자료',
                name_en: 'Stats',
                is_open: this.state.isOpenStats
              }}
            />
            <NavSidebar
              nav_list={navContents}
              bem={bem}
              sub_sidebar={{
                Icon: MdSend,
                handleClick: this.handleClick,
                name_ko: 'SNS 마케팅',
                name_en: 'Contents',
                is_open: this.state.isOpenContents
              }}
            />
            <NavSidebar
              nav_list={navFilter}
              bem={bem}
              sub_sidebar={{
                Icon: MdOutlineFilterAlt,
                handleClick: this.handleClick,
                name_ko: '필터 관리',
                name_en: 'Filter',
                is_open: this.state.isOpenFilter
              }}
            />
            <NavSidebar
              nav_list={navTranslation}
              bem={bem}
              sub_sidebar={{
                Icon: AiOutlineBook,
                handleClick: this.handleClick,
                name_ko: '번역 관리',
                name_en: 'Translation',
                is_open: this.state.isOpenTranslation
              }}
            />
            <NavSidebar
              nav_list={navCrawling}
              bem={bem}
              sub_sidebar={{
                Icon: MdArrowCircleDown,
                handleClick: this.handleClick,
                name_ko: '수집 관리',
                name_en: 'Crawling',
                is_open: this.state.isOpenCrawling
              }}
            />
            <NavSidebar
              nav_list={navPayment}
              bem={bem}
              sub_sidebar={{
                Icon: CgDollar,
                handleClick: this.handleClick,
                name_ko: '결제',
                name_en: 'Payment',
                is_open: this.state.isOpenPayment
              }}
            />
            <NavSidebar
              nav_list={navMail}
              bem={bem}
              sub_sidebar={{
                Icon: FiMail,
                handleClick: this.handleClick,
                name_ko: '메일',
                name_en: 'Mail',
                is_open: this.state.isOpenMail
              }}
            />
            <NavSidebar nav_list={navInvoice} bem={bem}/>
          </Nav>
        </div>
      </aside>
    )
  }
}

export default Sidebar