import AxiosRestApi from "@hooks/AxiosRestApi";

export async function ApiWithHeader(
  {param, url, headers, type = 'POST', is_target_total=false, is_result=true}
) {
  const api = await new AxiosRestApi();
  const {data, success} = await api.RestApi({url, param, headers, type});
  if (success) {
    return {
      error: false,
      result: is_result ? data.result : data,
      total: is_result ?  is_target_total ? data.target_total : data.total : data.length
    };
  }
  return {error: true, result: is_result ? data.detail : data, total: 0};
}


export async function ApiNoneHeader({param, url, type = 'POST', is_result=true}) {
  const api = await new AxiosRestApi()
  const {data, success} = await api.RestApi({url, param, headers:undefined, type})
  if (success) {
    return {
      error: false, result: is_result ? data.result : data, total: data.total
    }
  }
  return {error: true, result: is_result ? data.detail : undefined, total: 0}
}