import React from "react";
import {Button, ListGroup, ListGroupItem, Nav, Navbar, NavItem, NavLink, Popover, PopoverBody} from "reactstrap";
import {MdClearAll, MdExitToApp, MdOutlineAccountCircle} from "react-icons/md";
import bn from '@utils/bemnames';
import UserCard from "@components/Card/UserCard";
import Avatar from "@components/Avatar";
import {user} from "@static/Config";

const bem = bn.create('header');

class Header extends React.Component {
  state = {
    isOpenNotificationPopover: false,
    isNotificationConfirmed: false,
    isOpenUserCardPopover: false,
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };

  handleSignOut = () => {
    localStorage.removeItem('user')
    localStorage.removeItem('Authorization')
    window.location.href = '/'
  }

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };
  render() {
    return (
      <Navbar light expand className={bem.b('bg-white')}>
        <Nav navbar className="mr-2">
          <Button outline onClick={this.handleSidebarControlButton}>
            <MdClearAll size={25} />
          </Button>
        </Nav>
        <Nav navbar className={bem.e('nav-right')}>
          <NavItem>
            <NavLink id="Popover2">
              {(user && user.photo_path) ? (
                <Avatar
                  onClick={this.toggleUserCardPopover}
                  className="can-click"
                  src={user.photo_path}
                />
              ) : (
                <MdOutlineAccountCircle style={{width: 40, height: 40,}}/>
              )}
            </NavLink>
            <Popover
              placement="bottom-end"
              isOpen={this.state.isOpenUserCardPopover}
              toggle={this.toggleUserCardPopover}
              target="Popover2"
              className="p-0 border-0"
              style={{ minWidth: 250 }}
            >
              <PopoverBody className="p-0 border-light">
                <UserCard
                  avatar={(user && user.photo_path) ? user.photo_path : ''}
                  title={(user && user.nickname) ? user.nickname : ''}
                  subtitle={(user && user.user_id) ? user.user_id : ''}
                  className="border-light"
                >
                  <ListGroup flush>
                    <ListGroupItem tag="button" action className="border-light" onClick={this.handleSignOut}>
                      <MdExitToApp /> Signout
                    </ListGroupItem>
                  </ListGroup>
                </UserCard>
              </PopoverBody>
            </Popover>
          </NavItem>
        </Nav>
      </Navbar>
    );
  }
}

export default Header