import React from "react";
import componentQueries from "react-component-queries";
import MainLayout from "@components/Layout/MainLayout";
import '@styles/reduction.scss';
import PageSpinner from "@components/PageSpinner";
import GAListener from "@components/GAListener";
import {EmptyLayout, LayoutRoute} from "@components/Layout";
import SignIn from "@pages/SignIn";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import ScrollToTop from "@hooks/ScrollToTop/ScrollToTop";

const Invoice = React.lazy(() => import('@pages/Invoice'));
const Dashboard = React.lazy(() => import('@pages/Dashboard'));
const ManageCampaign = React.lazy(() => import('@pages/ManageCampaign'));
const ManageContents = React.lazy(() => import('@pages/ManageContents'));
const ManageFilter = React.lazy(() => import('@pages/ManageFilter'));
const ManageStats = React.lazy(() => import('@pages/ManageStats'));
const ManageSupport = React.lazy(() => import('@pages/ManageSupport'));
const ManageUser = React.lazy(() => import('@pages/ManageUser'));
const ManageMarketData = React.lazy(() => import('@pages/ManageMarketData'));
const ManageCrawling = React.lazy(() => import('@pages/ManageCrawling'));
const ManageTranslation = React.lazy(() => import('@pages/ManagerTranslation'));
const ManagePayment = React.lazy(() => import('@pages/ManagePayment'));
const ManageMail = React.lazy(() => import('@pages/ManageMail'));
const ManageProduct = React.lazy(() => import('@pages/ManageProduct'));
const ManageService = React.lazy(() => import('@pages/ManageService'));

class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <GAListener>
          <Switch>
            <LayoutRoute
              exact
              path={['/']}
              layout={EmptyLayout}
              component={props => (
                <SignIn />
              )}
            />
            <MainLayout breakpoint={this.props.breakpoint}>
              <React.Suspense fallback={<PageSpinner />}>
                <ScrollToTop/>
                <Route
                  path={['/invoice', '/invoice/:type', '/invoice/:type/:sub_menu', '/invoice/:type/:sub_menu/:id']}
                  exact
                  component={Invoice}
                />
                <Route path={["/dashboard"]} exact component={Dashboard}/>
                <Route
                  path={['/manage-campaign', '/manage-campaign/:type', '/manage-campaign/:type/:id']}
                  exact
                  component={ManageCampaign}
                />
                <Route
                  path={['/manage-contents', '/manage-contents/:type', '/manage-contents/:type/:id']}
                  exact
                  component={ManageContents}
                />
                <Route
                  path={['/manage-filter', '/manage-filter/:type', '/manage-filter/:type/:id']}
                  exact
                  component={ManageFilter}
                />
                <Route
                  path={['/stats', '/stats/:type']}
                  exact
                  component={ManageStats}
                />
                <Route
                  path={[
                    '/manage-support',
                    '/manage-support/:type',
                    '/manage-support/:type/:subMenu',
                    '/manage-support/:type/:subMenu/:id'
                  ]}
                  exact
                  component={ManageSupport}
                />
                <Route
                  path={['/manage-user', '/manage-user/:type', '/manage-user/:type/:id']}
                  exact
                  component={ManageUser}
                />
                <Route
                  path={['/manage-market-data', '/manage-market-data/:type', '/manage-market-data/:type/:id']}
                  exact
                  component={ManageMarketData}
                />
                <Route
                  path={['/manage-crawling', '/manage-crawling/:type', '/manage-crawling/:type/:id']}
                  exact
                  component={ManageCrawling}
                />
                <Route
                  path={['/manage-translation', '/manage-translation/:type']}
                  exact
                  component={ManageTranslation}
                />
                <Route
                  path={['/manage-payment', '/manage-payment/:type']}
                  exact
                  component={ManagePayment}
                />
                <Route
                  path={['/manage-mail', '/manage-mail/:type', '/manage-mail/:type/:id']}
                  exact
                  component={ManageMail}
                />
                <Route
                  path={['/manage-product', '/manage-product/:type']}
                  exact
                  component={ManageProduct}
                />
                <Route
                  path={['/manage-service', '/manage-service/:type']}
                  exact
                  component={ManageService}
                />
              </React.Suspense>
            </MainLayout>
            <Redirect to='/'/>
          </Switch>
        </GAListener>
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);

