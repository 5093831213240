import React from "react";
import {user} from "@static/Config";
import {IconType} from "react-icons/lib";
import {Collapse, NavItem, NavLink as BSNavLink} from "reactstrap";
import {NavLink} from "react-router-dom";
import {MdKeyboardArrowDown} from "react-icons/md";

interface INavList {
  to: string,
  name: string,
  exact: boolean,
  Icon: IconType,
  permissions: number[]
}

interface INavSidebar {
  nav_list: INavList[],
  bem: any,
  sub_sidebar?: {
    Icon: IconType,
    handleClick(name: any): void,
    name_ko: string,
    name_en: string,
    is_open: boolean
  }
}

const NavSidebarMulti = ({nav_list, bem, sub_sidebar}: INavSidebar) => {
  if (sub_sidebar) {
    const {Icon, handleClick, name_ko, name_en, is_open} = sub_sidebar;
    return (
      <>
        <NavItem
            className={bem.e('nav-item')}
            onClick={handleClick(name_en)}
        >
          <BSNavLink className={bem.e('nav-item-collapse')}>
            <div className="d-flex">
              <Icon className={bem.e('nav-item-icon')}/>
              <span className=" align-self-start">{name_ko}</span>
            </div>
            <MdKeyboardArrowDown
                className={bem.e('nav-item-icon')}
                style={{
                  padding: 0,
                  transform: is_open ? 'rotate(0deg)' : 'rotate(-90deg)',
                  transitionDuration: '0.3s',
                  transitionProperty: 'transform',
                }}
            />
          </BSNavLink>
        </NavItem>
        <Collapse isOpen={is_open}>
          {nav_list.map(({to, name, exact, Icon}, index) => (
              <NavItem key={index} className={bem.e('nav-item')}>
                <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                >
                  <Icon className={bem.e('nav-item-icon')}/>
                  <span className="">{name}</span>
                </BSNavLink>
              </NavItem>
          ))}
        </Collapse>
      </>
    );
  } else {
    return (<></>);
  }
}

const NavSidebarSingle = ({nav_data, bem}: { nav_data: INavList, bem: any }) => {
  return (
    <NavItem key={0} className={bem.e('nav-item')}>
      <BSNavLink
        id={`navItem-${nav_data.name || ''}-0`}
        className="text-uppercase"
        tag={NavLink}
        to={nav_data.to}
        activeClassName="active"
        exact={nav_data.exact}
      >
        <nav_data.Icon className={bem.e('nav-item-icon')} />
        <span className="">{nav_data.name}</span>
      </BSNavLink>
    </NavItem>
  );
}

const NavSidebar = ({nav_list, bem, sub_sidebar}: INavSidebar) => {
  if (user) {
    const user_type = user.type_id
    const nav_filter = nav_list.filter(item => item.permissions && item.permissions.includes(user_type));
    const permission = !!nav_filter.length;

    if (permission) {
      if (sub_sidebar && nav_filter.length > 0) {
        return (
          <NavSidebarMulti bem={bem} nav_list={nav_filter} sub_sidebar={sub_sidebar} />
        );
      } else if (nav_filter.length === 1) {
        return (<NavSidebarSingle nav_data={nav_list[0]} bem={bem}/>)
      }
    }
    return (<></>);

  } else {
    window.location.reload();
  }
}

export default NavSidebar