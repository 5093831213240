import React, {useState} from "react";
import {Button, Card, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import incode8_logo from "@assets/img/logo/incode8_logo.png"
import {ApiNoneHeader, ApiWithHeader} from "@static/getApi";
import {usertype} from "@static/Config";
import {useHistory} from "react-router-dom";

function SignIn () {
  const history = useHistory();
  const saveUserId = localStorage.getItem('save_user_id')
  const [userId, setUserId] = useState(saveUserId ? saveUserId : '');
  const [password, setPassword] = useState('');
  const [is_remember, setIsRemember] = useState(!!saveUserId);

  // 로그인된 경우 대시보드 페이지로 이동
  if (localStorage.getItem('user') && localStorage.getItem('Authorization')) {
    const headers = {Authorization : localStorage.getItem('Authorization')};
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/account/user`;
    ApiWithHeader({url, headers, type: 'GET'}).then(value => {
      if (!value.error) {
        alert('로그인 여부를 확인해주세요.');
        history.push('/dashboard');
      } else {
        localStorage.removeItem('user');
        localStorage.removeItem('Authorization');
      }
    })
  }

  // 각종 placeholder 설정
  const usernameLabel = 'email';
  const usernameInputProps = {
    type: 'email',
    placeholder: 'your@email.com',
  };
  const confirmPasswordLabel = 'password';
  const confirmPasswordInputProps = {
    type: 'password',
    placeholder: 'confirm your password',
  };

  // 아이디 저장
  const handleCheckBox = () => {
    setIsRemember(!is_remember)
    if (is_remember) {
      localStorage.removeItem('save_user_id')
    }
  };

  // 로그인 버튼
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (is_remember) {
      localStorage.setItem('save_user_id', userId)
    }
    const param = {
      user_id: userId,
      password: password
    }
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/account/login`
    const login = await ApiNoneHeader({param, url})
    if (login.error) {
      alert('아이디 또는 비밀번호를 다시 확인해주세요.')
    } else {
      if ([usertype.admin, usertype.admin_marketing, usertype.admin_management_support].includes(login.result.user.type_id)) {
        localStorage.setItem('Authorization', `Token ${login.result.token}`)
        localStorage.setItem('user', JSON.stringify({
          id: login.result.user.id,
          type_id: login.result.user.type_id,
          nickname: login.result.user.nickname,
          brand_name_ko: login.result.user.brand_name_ko,
          photo_path: login.result.user.photo_path,
          user_id: login.result.user.user_id
        }))
        history.push('/dashboard')
        window.location.reload();
      } else {
        alert('관리자만 로그인할 수 있습니다.')
      }
    }
  };

  return(
    <Row
      style={{
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Col md={6} lg={4}>
        <Card body>
          <Form onSubmit={handleSubmit}>
            <div className="text-center pb-4">
              <img
                src={incode8_logo}
                width='140'
                className="rounded"
                alt='incode8 Logo'
              />
            </div>
            <FormGroup>
              <Label for={usernameLabel}>{usernameLabel}</Label>
              <Input
                {...usernameInputProps}
                value={userId}
                onChange={(event) => setUserId(event.target.value)}
                onKeyDown={(event) => event.keyCode === 13 && handleSubmit(event)}
              />
            </FormGroup>
            <FormGroup>
              <Label for={confirmPasswordLabel}>{confirmPasswordLabel}</Label>
              <Input
                {...confirmPasswordInputProps}
                onChange={(event) => setPassword(event.target.value)}
                onKeyDown={(event) => event.keyCode === 13 && handleSubmit(event)}
              />
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  checked={is_remember}
                  onChange={handleCheckBox}
                />
                {'Remember me'}
              </Label>
            </FormGroup>
            <hr />
            <Button
              size="lg"
              className="bg-gradient-theme-left border-0"
              block
              onClick={handleSubmit}
            >
              {'Login'}
            </Button>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}

export default SignIn
