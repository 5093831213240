import React from 'react';
import {Link} from "react-router-dom";
import incode8_logo from "@assets/img/logo/incode8_logo.png";
import {Navbar} from "reactstrap";
import bemNames from "@utils/bemnames";

const bem = bemNames.create('footer');

class Footer extends React.Component {
  render() {
    return (
      <div className={bem.b()}>
        <div className="footer-top">
          <Navbar>
            <Link to='/'>
              <img
                src={incode8_logo}
                width='140'
                className="pr-2"
                alt='incode8 Logo'
              />
            </Link>
          </Navbar>
        </div>
        <div className='divider' />
        <div className="footer-bottom">
          <p>COPYRIGHT(C) 2021 AIDEN LAB. ALL RIGHTS RESERVED.</p>
          <p>㈜에이든랩  |  대표이사 : 강철용  |  개인정보 보호 최고책임자 : 오석훈</p>
          <p>사업자등록번호 : 464-87-01922  |  통신판매업신고번호 : 2021-서울강서-3852</p>
          <p>주소 : 서울 강서구 공항대로 227 (마곡동, 마곡센트럴타워Ⅰ) 1201호  |
            Address : 227, Gonghang-daero, Gangseo-gu, Seoul, Republic of Korea</p>
          <p>메일 : apolo@aidenlab.io  |  TEL : 02-6737-1922  |  FAX : 070-5158-5600</p>
        </div>
      </div>
    );
  }
}

export default Footer
